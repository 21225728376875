
  .custom-modal .ant-modal-content {
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 0 !important;
    max-height: 90vh !important;
    top: 0 !important;
    margin: 30px auto;
    max-width: 100vw !important;
  }
  
  .custom-modal .ant-modal {
    max-width: 100vw !important;
    max-width: none !important;
  }
